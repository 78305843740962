import React from 'react';
import {InfoContainer, InfoRow, Column1, TextWrapper, TitleWrapper,
    Heading,Subtitle, BtnWrap, Column2, Designation , PageTitle} from './InfoElements';
import {Button} from '../ButtonElement';

const BothSide = ({data}) => {
    const [amit,priyam] = data;

  return (
    <>
    <InfoContainer lightBg={amit.lightBg} id={amit.id}>
    <PageTitle>{amit.topLine}</PageTitle>
        <TitleWrapper>
            <InfoRow imgStart={amit.imgStart}>
                <Column1>
                    <TextWrapper>
                        {/* <TopLine>{amit.topLine}</TopLine> */}
                        <Heading lightText={amit.lightText}>{amit.headline}</Heading>
                        <Designation lightText={amit.lightText} >{amit.designation}</Designation>
                        <Subtitle darkText={amit.darkText}>{amit.description}</Subtitle>
                        <BtnWrap >
                            <Button
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-80}
                            primary={amit.primary?1:0}
                            dark={amit.dark?1:0}
                            dark2={amit.dark2?1:0}
                            to='home'>{amit.buttonLabel}</Button>
                        </BtnWrap>
                    </TextWrapper>
                </Column1>
                <Column2>
                    <TextWrapper>
                        {/* <TopLine>{priyam.topLine}</TopLine> */}
                        <Heading lightText={priyam.lightText}>{priyam.headline}</Heading>
                        <Designation lightText={amit.lightText} >{priyam.designation}</Designation>
                        <Subtitle darkText={priyam.darkText}>{priyam.description}</Subtitle>
                        <BtnWrap >
                            <Button
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-80}
                            primary={priyam.primary?1:0}
                            dark={priyam.dark?1:0}
                            dark2={priyam.dark2?1:0}
                            to='home'>{priyam.buttonLabel}</Button>
                        </BtnWrap>
                    </TextWrapper>
                </Column2>
            </InfoRow>
        </TitleWrapper>
    </InfoContainer>
    </>
  )
}

export default BothSide;