export const homeObjOne = {
    id: 'Vision',
    lightBg: true,
    lightText: false,
    lightDescription: false,
    topLine: 'Vision',
    headline: 'Lubricate the inefficient  process  of steel trading.',
    description: 'Becoming a preferred supplier by offering superior services and products and adding value to our clients.',
    buttonLabel: 'Sign Up now',
    imgStart: false,
    img: require('../../images/1.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true
}

export const teamData = [{
    id: 'Team',
    lightBg: false,
    lightText: true,
    lightDescription: true,
    topLine: 'Our Team',
    headline: 'Mr. Priyam Shukla',
    designation: 'Co-Founder and CEO',
    description: ' Comes with incredible business acumen having two generations of experience in the industry.  ',
    buttonLabel: 'Connect',
    imgStart: true,
    img: require('../../images/2.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    buttonAction: `window.open('mailto:priyam@steeleasy.in','blank')`,
},
{
    id: 'Team',
    lightBg: false,
    lightText: true,
    lightDescription: true,
    topLine: 'Our Team',
    headline: 'Mr. Amit Verma',
    designation: 'Co-Founder and CTO',
    description: ' Has extensive experience in the software industry. He graduated from IIT-Madras with a  master’s degree in computer science engineering.',
    buttonLabel: 'Connect',
    imgStart: false,
    img: require('../../images/2.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    buttonAction: ()=>window.open('mailto:amit@steeleasy.in','blank'),
}
]

export const homeObjFour = {
    id: 'Career',
    lightBg: true,
    lightText: false,
    lightDescription: false,
    topLine: 'We are hiring',
    headline: 'Front end and Backend positions are open',
    description: 'Hiring ReactJs developer and/or NodeJs-Express framework developers with minimum 2 yrs experience. Send your resume at career@steeleasy.in',
    buttonLabel: 'or direct apply',
    imgStart: false,
    img: require('../../images/3.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true
}

export const homeObjFive = {
    id: 'Contact',
    lightBg: false,
    lightText: true,
    lightDescription: true,
    topLine: 'Contact SteelEasy',
    headline: 'Have any query?',
    description: 'Write mail to us at enquire@steeleasy.in',
    buttonLabel: 'or drop a message',
    imgStart: true,
    img: require('../../images/4.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}
