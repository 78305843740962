import React, {useState} from 'react'
import {HomeContainer, HomeBg, VideoBg, HomeContent, HomeHeading, HomeBody, HomeButtonWrapper,ArrowForward, ArrowRight } from './HomeElement';
import Video from '../../videos/steelProduction.mp4'
import { Button } from '../ButtonElement.js';

function HomeSection() {
    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(!hover);
    }
  return (
    <HomeContainer id='home'>
        <HomeBg>
            <VideoBg autoPlay muted loop src={Video} type='video/mp4'></VideoBg>
        </HomeBg>
        <HomeContent>
            <HomeHeading>
                Providing high-quality steel products, services with cutting edge technology
            </HomeHeading>
            <HomeBody>
            To provide high-quality steel products, related services, and solutions to a global client base while utilising innovative technologies in an ecosystem of highly motivated employees who are committed to innovation, the highest business standards, strong work ethic, and corporate citizenship, resulting in enhanced value for our consumers and a prolonged investment return for our shareholders.
             </HomeBody>
            <HomeButtonWrapper>
                <Button  primary={true} dark={true} to="signUp" onMouseEnter={onHover} onMouseLeave={onHover}>Get Started{hover?<ArrowForward/>:<ArrowRight/>}</Button>
            </HomeButtonWrapper>
        </HomeContent>
    </HomeContainer>
  );
}

export default HomeSection