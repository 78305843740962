import React from 'react'
import {ProductContainer, ProductsH1, ProductsWrapper, ProductCard, ProductIcon, ProductsH2, ProductP} from './ProductElements'
import item1 from '../../images/1.svg';
import item2 from '../../images/2.svg';
import item3 from '../../images/3.svg';
import item4 from '../../images/4.svg';
import item5 from '../../images/5.svg';
const Products = () => {
  return (
    <ProductContainer id='product'>
        <ProductsH1>Products we deal in</ProductsH1>
        <ProductsWrapper>
            <ProductCard>
                <ProductIcon src={item1}></ProductIcon>
                <ProductsH2>
                    Billets
                </ProductsH2>
                <ProductP>Size: 100mm, 125mm,  160mm</ProductP>
            </ProductCard>
            <ProductCard>
                <ProductIcon src={item2}></ProductIcon>
                <ProductsH2>
                Sponge Iron
                </ProductsH2>
                <ProductP>Size: 3 mm to 20 mm</ProductP>
            </ProductCard>
            <ProductCard>
                <ProductIcon src={item3}></ProductIcon>
                <ProductsH2>
                    TMT bars
                </ProductsH2>
                <ProductP>Size: 8mm to 32mm</ProductP>
            </ProductCard>
            <ProductCard>
                <ProductIcon src={item4}></ProductIcon>
                <ProductsH2>
                    Pig Iron
                </ProductsH2>
                <ProductP>C 3.5-4% Si 1-1.5% S .05-.08% Ph .08 - .12%</ProductP>
            </ProductCard>
            <ProductCard>
                <ProductIcon src={item5}></ProductIcon>
                <ProductsH2>
                Pellets
                </ProductsH2>
                <ProductP>Size: 5mm to  18mmm</ProductP>
            </ProductCard>
        </ProductsWrapper>
    </ProductContainer>
  )
}

export default Products;